import { Helmet } from "react-helmet-async";
import { Outlet, Link } from "react-router-dom";
import { PageBody } from "../components/PageBody";
import { Footer } from "../components/utils/Footer";
import { Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
	CpuChipIcon,
	ChartBarIcon,
	LightBulbIcon,
	Bars3Icon,
	MagnifyingGlassIcon,
	XMarkIcon,
  CircleStackIcon,
} from '@heroicons/react/24/outline';
import { PoolIcon } from "../components/Icons";


export const NavBar = () => {
	// Reference for width tracking later
	// https://thewebdev.info/2021/05/24/how-to-get-the-width-of-an-element-in-a-react-component/
	// https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element
	return (
		<div className="flex flex-col min-h-screen justify-between">
			<Helmet>
				<title>VizBadger | Sports Analytics, Data-Driven Projects and Visualisation</title>
				<meta
					name="description"
					content="Discover, interact and explore with data visuals on sports analytics, simulations, data visualisation and sports betting."
				></meta>
			</Helmet>
			<nav className="sticky top-0 z-50 bg-badger-off-white">
        <PageBody>
					<NavBarContent />
				</PageBody>
			</nav>
			<div className="mt-4 mx-4 mb-auto relative">
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};


// https://tailwindui.com/components/marketing/elements/headers
const projects = [
  {
    name: 'Test Match Batting Careers',
    description: 'Search and explore cricketers\' batting careers through visualisations and data analytics.',
    href: 'cricket',
    icon: MagnifyingGlassIcon,
    iconColour: 'text-badger-blue',
  },
  {
    name: 'English Pool Monte Carlo Simulation',
    description: 'Simulate and predict pool games using player shot accuracy and break efficiency. Adjust their skills and attributes to view the impact on results.',
    href: 'sports-models/english-pool-monte-carlo-simulation',
    icon: CpuChipIcon,
    iconColour: 'text-badger-green',
  },
  {
    name: 'Maximise Betting Accumulators',
    description: 'Maximise profit from sports betting accumulators by understanding how timing and selecting the right bookmaker makes all the difference.',
    href: 'betting/how-to-maximise-sports-betting-accumulators',
    icon: LightBulbIcon,
    iconColour: 'text-badger-yellow',
  },
  {
    name: 'Dynamic D3.js Graph',
    description: "Interact with a dynamic graph that automatically scales to device size with D3.js.",
    href: 'data-visualisation/dynamic-d3-components',
    icon: ChartBarIcon,
    iconColour: 'text-badger-purple',
  },
  {
    name: 'Amateur Pool Rankings',
    description: "Use the Colley ranking system to calculate which amateur pool player is the best.",
    href: 'pool/matches',
    icon: PoolIcon,
    iconColour: 'text-badger-red',
  },
  {
    name: 'Designing Analytics Schemas',
    description: "Improve your analytics by designing effective relational database models and structures that answer business problems.",
    href: 'analytics/designing-analytics-schemas',
    icon: CircleStackIcon,
    iconColour: 'text-gray-700',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
};


export const NavBarContent = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setIsScrolled(scrolled);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isScrolled]);

  return (
    <Popover className="relative">
      <div className="px-4 lg:px-0">
        {/* Mobile Layout - consistently horizontal */}
        <div 
          className={`flex flex-row justify-between items-center md:hidden transition-all duration-200 ease-out
            ${isScrolled 
              ? 'h-10 py-1.5' 
              : 'h-16 py-3'
            }`}
        >
          <Link to="/">
            <img
              className={`transition-all duration-200
                ${isScrolled ? 'h-7' : 'h-10'}`}
              src="/logos/fullPurpleGrey.png"
              alt="VizBadger main logo"
            />
          </Link>
          <Popover.Button 
            className={`transition-all duration-200
              ${isScrolled 
                ? 'px-2 py-1' 
                : 'px-3 py-1.5'} 
              rounded-md inline-flex items-center justify-center text-gray-500 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-badger-purple`}
          >
            <span className="text-sm font-medium mr-2">Projects</span>
            <Bars3Icon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>
        </div>

        {/* Desktop Layout */}
        <div className={`hidden md:flex items-center justify-between transition-all duration-200 ease-out
            ${isScrolled 
              ? 'py-2' 
              : 'py-4'
            }`}>
          <div className="flex-none">

            <Link to="/" className="flex justify-start">
              <img
                className={`transition-all duration-200 hover:scale-105 ${
                  isScrolled ? 'h-7' : 'h-10'
                }`}
                src="/logos/fullPurpleGrey.png"
                alt="VizBadger main logo"
              />
            </Link>
          </div>

          <Popover.Group as="nav" className="flex items-center ml-8">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-badger-purple' : 'text-gray-700',
                      'group rounded-md inline-flex items-center font-medium hover:text-badger-purple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-badger-purple transition-all duration-200',
                      isScrolled ? 'text-base py-1.5 px-3' : 'text-lg py-2 px-4'
                    )}
                  >
                    <span>Projects</span>
                    <Bars3Icon
                      className={classNames(
                        open ? 'text-badger-purple' : 'text-gray-700',
                        'ml-1 h-6 w-6 group-hover:text-badger-purple'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  {/* Projects dropdown panel - keeping existing implementation */}
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-xl sm:px-0 right-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-4 sm:gap-6 sm:p-8">
                          {projects.map((item) => (
                            <Popover.Button
                              as={Link}
                              key={item.name}
                              to={item.href}
                              className="-m-2 p-2 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              {item.name === "Amateur Pool Rankings" ? <item.icon classes={`flex-shrink-0 h-6 w-6 ${item.iconColour}`} aria-hidden="true" /> : <item.icon className={`flex-shrink-0 h-6 w-6 ${item.iconColour}`} aria-hidden="true" />}
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1/2 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </Popover.Button>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
        </div>
      </div>

      {/* Mobile menu panel */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between mb-4">
                <Popover.Button as={Link} to="/">
                  <img
                    className="h-12"
                    src="/logos/fullPurpleGrey.png"
                    alt="VizBadger main logo"
                  />
                </Popover.Button>
                <div className="-mr-2">
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-badger-purple">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-4">
                  {projects.map((item) => (
                    <Popover.Button as={Link}
                      key={item.name}
                      to={item.href}
                      className="p-3 flex items-center rounded-md hover:bg-gray-50 transition-colors"
                    >
                      {item.name === "Amateur Pool Rankings" 
                        ? <item.icon classes={`flex-shrink-0 h-6 w-6 ${item.iconColour}`} aria-hidden="true" /> 
                        : <item.icon className={`flex-shrink-0 h-6 w-6 ${item.iconColour}`} aria-hidden="true" />
                      }
                      <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
