import { simulateGame } from "./simulateGame";


// Aggregate the results of a league
const aggregateResults = (players, results) => {
    let wins;
    let matches;
    let score_for;
    let score_against;
    let player_stats = { 'table': [] };


    players.forEach((player, i) => {
        matches = results.filter((result) => result.player_ids.includes(player.player_id));
        wins = matches.filter((result) => result.winner_id === player.player_id).length;
        score_for = matches.map(match => match[player.player_id].score).reduce((a, b) => a + b, 0);
        score_against = matches.map(match => match[player.player_id].score_against).reduce((a, b) => a + b, 0);

        player_stats[player.player_id] = {
            'player_id': player.player_id,
            'played': matches.length,
            'wins': wins,
            'points': wins * 2,
            'score_for': score_for,
            'score_against': score_against,
            'score_difference': score_for - score_against,
            'matches': matches
        };

        player_stats['table'][i] = { 'position': -1, 'player_id': player.player_id, ...player_stats[player.player_id] }
    });

    player_stats.table.sort((a, b) => b.points - a.points || b.score_difference - a.score_difference);

    for (let j = 0; j < player_stats.table.length; j++) {
        player_stats.table[j]['position'] = j + 1;
        player_stats[player_stats.table[j]['player_id']]['position'] = j + 1;
    };

    return player_stats;
};


// Simulate the fixtures of a league
const simulateLeagueFixtures = (players, fixtures) => {
    // Format: {player_id: {opponent_id: {'player_break': n (int), 'opponent_break': n (int)}}}

    // If 'player_break' === 'opponent_break' then 'player_id' breaks, else min to even break opportunities
    let breakTracker = {};
    const flatFixtures = fixtures.flat();
    let results = [];

    for (let i = 0; i < players.length; i++) {
        const player = players[i];
        breakTracker[player.player_id] = {};
        for (let j = 0; j < players.length; j++) {
            const opponent = players[j];
            if (opponent.player_id !== player.player_id) {
                breakTracker[player.player_id][opponent.player_id] = 0
            };
        };
    };

    let breakPlayer = -1;
    let player_1Breaks = -1;
    let player_2Breaks = -1;

    flatFixtures.forEach((fixture, i) => {
        player_1Breaks = breakTracker[fixture.player_1.player_id][fixture.player_2.player_id];
        player_2Breaks = breakTracker[fixture.player_2.player_id][fixture.player_1.player_id];

        if (player_1Breaks < player_2Breaks) {
            breakPlayer = 'player_1';
            breakTracker[fixture.player_1.player_id][fixture.player_2.player_id] = player_1Breaks + 1;
        } else if (player_1Breaks === player_2Breaks & Math.random() < 0.5) {
            breakPlayer = 'player_1';
            breakTracker[fixture.player_1.player_id][fixture.player_2.player_id] = player_1Breaks + 1;
        } else {
            breakPlayer = 'player_2';
            breakTracker[fixture.player_2.player_id][fixture.player_1.player_id] = player_2Breaks + 1;
        };

        // Simulate the game
        results[i] = simulateGame(fixture, breakPlayer)
    });

    return aggregateResults(players, results);
};


// Simulate a league N times
const simulateNLeagues = (players, fixtures, nSimulations) => {
    let arr = new Array(nSimulations).fill(null);

    for (let i = 0; i < arr.length; i++) {
        arr[i] = simulateLeagueFixtures(players, fixtures);
    };

    // let playerResultsLookup = {};
    let summarisedPlayerResults = [];
    let playerResults = [];
    players.forEach(player => {
        for (let i = 0; i < arr.length; i++) {
            const simulation = arr[i];
            playerResults.push(simulation[player.player_id]);
        };

        summarisedPlayerResults.push({
            'player_id': player.player_id,
            'averageGamesPlayed': playerResults.reduce((total, next) => total + next.matches.length, 0) / playerResults.length,
            'averagePoints': playerResults.reduce((total, next) => total + next.points, 0) / playerResults.length,
            'averageWins': playerResults.reduce((total, next) => total + next.wins, 0) / playerResults.length,
            'averageScoreDifference': playerResults.reduce((total, next) => total + next.score_difference, 0) / playerResults.length,
            'positionChances': Array.from({ length: players.length }, (_, index) => {
                const position = index + 1;
                const count = playerResults.filter(simulation => simulation.position === position).length;
                return {
                    position: position,
                    percentage: (count / playerResults.length) * 100
                };
            }).reduce((acc, curr) => {
                acc[curr.position] = curr.percentage;
                return acc;
            }, {}),
            'firstPlaceChance': playerResults.filter(simulation => simulation.position === 1).length / playerResults.length,
            'lastPlaceChance': playerResults.filter(simulation => simulation.position === players.length).length / playerResults.length,
        });

        playerResults = [];
    });
    
    summarisedPlayerResults.sort((a, b) => b.averagePoints - a.averagePoints || b.firstPlaceChance - a.firstPlaceChance);
    return summarisedPlayerResults;
};


export { simulateLeagueFixtures, simulateNLeagues };