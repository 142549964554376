import { useEffect, useRef } from "react";
import * as d3 from "d3";

export const PositionChancesChart = ({ chances }) => {
    const svgRef = useRef();
    const height = 35; // Height of the SVG
    const width = 10; // Width of each rectangle
    const barSpacing = 1; // Space between bars
    const maxYValue = 100; // Maximum value for the y-axis: it's 100%


    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const data = Object.entries(chances).map(([position, chance]) => ({
            position: position,
            chance: chance,
        }));

        svg.selectAll("*").remove(); // Clear previous content

        // Create rectangles
        svg.selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d, i) => i * (width + barSpacing)) // Position each bar based on index
            .attr("y", (d) => height - (d.chance > 0 ? (d.chance / maxYValue) * height : 0)) // Position from the top
            .attr("width", width)
            .attr("height", (d) => (d.chance > 0 ? (d.chance / maxYValue) * height : 0)) // Scale height based on maxYValue
            .attr("fill", (d, i) => d.chance > 0 ? d3.schemeCategory10[i % 10] : "transparent"); // Color based on index or transparent

        // Set the SVG width based on the number of bars
        svg.attr("width", data.length * (width + barSpacing));
    }, [chances]);

    return (
        <svg ref={svgRef} height={height}></svg> // Height remains fixed
    );
};

