import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';
import { ArticleParagraph } from "../../../components/Paragraphs";
import { useEffect, useState } from "react";
import { generatePoolPlayers } from "./utility/poolPlayerCreation";
import { generateNRoundRobinPairings } from "./utility/fixtureGeneration";
import { Loading } from "../../../components/Loading";
import { displayPercentage } from "../../../helpers";
import { simulateLeagueFixtures, simulateNLeagues } from "./utility/simulateLeague";
import { PlayerAttributesTable } from "./components/PlayerAttributesTable";
import { FixturesDisplay } from "./components/FixturesDisplay";


export const PoolLeagueSimulation = () => {
    const [randomPlayers, setRandomPlayers] = useState(null);
    const nPlayers = 5;
    const [fixtures, setFixtures] = useState(null);
    const [results, setResults] = useState(null);
    const [multipleLeagueSimulations, setMultipleLeagueSimulations] = useState([]);

    useEffect(() => {
        const players = generatePoolPlayers(nPlayers);
        const fixtures = generateNRoundRobinPairings(players, 4);
        const simulationResults = simulateLeagueFixtures(players, fixtures);
        const multipleLeagueSimulationResults = simulateNLeagues(players, fixtures, 100);

        setRandomPlayers(players);
        setFixtures(fixtures);
        setResults(simulationResults.table);
        setMultipleLeagueSimulations(multipleLeagueSimulationResults);
    }, [nPlayers]);

    if (randomPlayers === null | fixtures === null | results === null) {
        return <Loading />
    };

    return (
        <PageBody>
            <Helmet>
                <title>English Pool Monte Carlo Simulation - League Simulation | VizBadger</title>
                <meta name="description" content="Generate a set of random players programmatically and then use Monte Carlo simulations to work out the most successful skillset when played across an entire season."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators, athlete statistics, super computer predicts sports outcomes"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/league-predictions" />
            </Helmet>

            <PoolSimulationHomeButton />

            <ArticleTitle title="League Prediction of Random Players" />

            <ArticleParagraph
                lines={[
                    "You see a lot of articles written about the super computers that are predicting the leagues. Companies like Opta will use their extensive statistics to build models and then come up with a predicted league based on thousands of simulations. FiveThirtyEight has my personal favourite demonstration of this.",
                    "To do this, you need to have a fixture list, be able to predict the outcome of games and then simulate the league multiple times. That is what I'm going to do below."
                ]}
            />

            <H2Heading>Generate random players</H2Heading>
            <div>
                <ArticleParagraph
                    lines={[
                        "For each of the IDs we have made a random player with a random skills and attributes as per the examples shown on the player model page.",
                    ]}
                />
            </div>

            <PlayerAttributesTable players={randomPlayers} />

            <ArticleParagraph
                lines={[
                    "Take a look at the players above, and try to intuitively guess who you think is the best and worst player in the lineup. Sometimes you'll find that it isn't as obvious as the top attribute, but all attributes play a part in contributing to the outcomes.",
                ]}
            />

            <H2Heading>Generate a fixture list</H2Heading>
            <FixturesDisplay fixtures={fixtures} />

            <ArticleParagraph

                lines={[
                    "Once we have a set of players, we can generate a fixture list. Everyone will play the other players multiple times (each player getting a chance to break to remove break bias from the fixtures), with 2 points for a win, and 0 points for a loss. Those tied on points will be decided by a score difference (like goal difference in football).",
                ]}
            />

            <H2Heading>Simulated League</H2Heading>
            <ArticleParagraph
                lines={[
                    "I've simulated through each of the fixtures in the league to crown a winner/s. The real power of this method will be the ability to simulate through the league a number of times. As we have seen with the individual simulations, even weaker players can have reasonably good success rates against top players, especially with strong breaking skills and streaks.",
                ]}
            />
            <div className="flex flex-row items-center justify-center mb-5">
                <table className="w-full sm:w-1/2 text-sm sm:text-base rounded">
                    <thead className="text-center">
                        <tr className="tracking-tight italic font-semibold bg-gray-100">
                            <td className="py-1 px-3">#</td>
                            <td className="p-1">Player</td>
                            <td className="p-1">Played</td>
                            <td className="p-1">Wins</td>
                            <td className="p-1">Score For</td>
                            <td className="p-1">Score Against</td>
                            <td className="p-1">Score Diff</td>
                            <td className="p-1">Points</td>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {results.map((player_result, i) => (
                            <tr key={player_result.player_id}>
                                <td className="py-1 border-r border-gray-100">{i + 1}</td>
                                <td className="py-1">{player_result.player_id}</td>
                                <td className="py-1">{player_result.played}</td>
                                <td className="py-1">{player_result.wins}</td>
                                <td className="py-1">{player_result.score_for}</td>
                                <td className="py-1">{player_result.score_against}</td>
                                <td className="py-1">{player_result.score_difference}</td>
                                <td className="py-1">{player_result.points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <H2Heading>Simulating 100 Leagues</H2Heading>
            <ArticleParagraph
                lines={[
                    "Take the above simulation and run that 100 times to see, generally, who is likely to win the league!",
                ]}
            />
            <div className="flex flex-row items-center justify-center">
                <table className="w-full sm:w-1/2 text-sm sm:text-base rounded">
                    <thead className="text-center">
                        <tr className="tracking-tight italic font-semibold bg-gray-100">
                            <td className="p-1">Player</td>
                            <td className="p-1">Avg. Points</td>
                            <td className="p-1">Avg. Wins</td>
                            <td className="p-1">Avg. Score Diff</td>
                            <td className="p-1">1st Chance</td>
                            <td className="p-1">Last Chance</td>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {multipleLeagueSimulations.map((player) => (
                            <tr key={player.player_id}>
                                <td className="p-1">{player.player_id}</td>
                                <td className="p-1">{player.averagePoints}</td>
                                <td className="p-1">{player.averageWins}</td>
                                <td className="p-1">{player.averageScoreDifference}</td>
                                <td className="p-1">{displayPercentage(player.firstPlaceChance, 2)}</td>
                                <td className="p-1">{displayPercentage(player.lastPlaceChance, 2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </PageBody>
    );
};
