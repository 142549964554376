import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { ArticleParagraph } from "../../../components/Paragraphs";
import { useEffect, useState, useRef } from "react";
import { Loading } from "../../../components/Loading";
import * as d3 from 'd3';
import { GolfModelsHomeButton } from "../../../components/HomeLinks";
import { useChartDimensions } from "../../../components/graphs/useChartDimensions";
import { ChartCanvas } from "../../../components/graphs/basicGraph";


// Function to simulate a single round score based on average and distribution
function simulateRoundScore(averageRound, distribution) {
    // The distribution parameter represents the spread or variability of scores around the average
    const standardDeviation = averageRound * (distribution / 100);
    // Generate a random round score using the normal distribution
    const roundScore = normalDistribution(averageRound, standardDeviation);
    return Math.round(roundScore);
}

// Function to generate random numbers from a normal distribution
function normalDistribution(mean, standardDeviation) {
    let u = 0,
        v = 0;
    while (u === 0) u = Math.random();
    while (v === 0) v = Math.random();
    const z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    return mean + z * standardDeviation;
}

// Function to simulate multiple rounds of golf for distribution analysis
function simulateGolfDistribution(averageRound, distribution, numberOfRounds = 500) {
    const roundScores = [];

    // Simulate multiple rounds to build a distribution
    for (let round = 1; round <= numberOfRounds; round++) {
        const roundScore = simulateRoundScore(averageRound, distribution);
        roundScores.push(roundScore);
    }

    return roundScores;
}

function groupAndCountScores(scores) {
    const scoreCounts = {};
    
    // Find the minimum and maximum scores
    const minScore = Math.min(...scores);
    const maxScore = Math.max(...scores);

    // Initialize all possible scores with 0
    for (let score = minScore; score <= maxScore; score++) {
        scoreCounts[score] = 0;
    }

    // Count the occurrences of each score
    scores.forEach((score) => {
        scoreCounts[score]++;
    });

    // Calculate the total number of scores
    const totalCount = scores.length;

    // Create an array of objects with score and count information
    const scoreInfo = Object.entries(scoreCounts).map(([score, count]) => ({
        score: parseInt(score),
        count,
        percentage: (count / totalCount) * 100,
    }));

    // Sort by score
    scoreInfo.sort((a, b) => a.score - b.score);

    return scoreInfo;
}


const ScoreDistributionTable = ({ scoreInfo, colourScale }) => {
    // Find the maximum percentage for color scaling
    const maxPercentage = Math.max(...scoreInfo.map(info => info.percentage));
    
    return (
        <div className="flex items-center justify-center flex-col text-sm sm:text-base text-center sm:text-left mt-4">
            <table className="w-full sm:w-4/5 text-sm sm:text-base border-collapse">
                <thead className="bg-gray-200">
                    <tr className="text-center tracking-tight">
                        <th className="px-1 py-2 sm:px-2 border border-gray-300">Score</th>
                        <th className="px-1 py-2 sm:px-2 border border-gray-300">Count</th>
                        <th className="px-1 py-2 sm:px-2 border border-gray-300">Percentage</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    {scoreInfo.map(({ score, count, percentage }) => (
                        <tr key={score}>
                            <td className="p-1 border border-gray-300">{score}</td>
                            <td className="p-1 border border-gray-300">{count}</td>
                            <td 
                                className="p-1 border border-gray-300 relative"
                                style={{ 
                                    backgroundColor: colourScale.current(percentage),
                                    color: percentage > maxPercentage/2 ? 'white' : 'black'
                                }}
                            >
                                <div className="relative z-10">
                                    {percentage.toFixed(2)}%
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


const MySliderComponent = ({ averageRound, updateAverageRound, distributionScore, updateDistributionScore }) => {
    const handleAverageRoundChange = event => {
        const value = parseFloat(event.target.value);
        updateAverageRound(isNaN(value) ? 0 : value);
    };

    const handleDistributionScoreChange = event => {
        const value = parseFloat(event.target.value);
        updateDistributionScore(isNaN(value) ? 0 : value);
    };

    return (
        <div className="flex mb-4 flex-col sm:flex-row text-sm sm:text-base">
            <div className="w-full sm:w-1/2 sm:px-5">
                <label htmlFor="averageRound" className="block mb-2 font-bold">
                    Average Round Score
                </label>
                <input
                    type="range"
                    id="averageRound"
                    className="w-full"
                    min="67"
                    max="77"
                    step="0.5"
                    value={averageRound}
                    onChange={handleAverageRoundChange}
                />
                <div className="text-center">{averageRound}</div>
            </div>

            <div className="w-full sm:w-1/2 sm:px-5">
                <label htmlFor="distributionScore" className="block mb-2 font-bold">
                    Distribution Score
                </label>
                <input
                    type="range"
                    id="distributionScore"
                    className="w-full"
                    min="0"
                    max="10"
                    step="1"
                    value={distributionScore}
                    onChange={handleDistributionScoreChange}
                />
                <div className="text-center">{distributionScore}</div>
            </div>
        </div>
    );
};

const ScoreHistogram = ({ scores, averageRound }) => {
    const [ref, dimensions] = useChartDimensions({
        marginTop: 30,
        marginRight: 20,
        marginBottom: 30,
        marginLeft: 40,
        height: 200
    });

    useEffect(() => {
        if (!scores || !scores.length || !dimensions.boundedWidth) return;

        // Select the bounds group directly
        const bounds = d3.select(".bounds");

        // Clear any existing content
        bounds.selectAll("*").remove();

        // Create scales with fixed bin size and extended domain
        const minScore = Math.floor(Math.min(...scores)) - 1;
        const maxScore = Math.ceil(Math.max(...scores)) + 1;
        const x = d3.scaleLinear()
            .domain([minScore, maxScore])
            .range([0, dimensions.boundedWidth]);

        // Calculate bar width with gap (98% of full width)
        const fullBarWidth = (x(minScore + 1) - x(minScore));
        const barWidth = fullBarWidth * 0.98;

        // Generate histogram data with fixed bins
        const histogram = d3.histogram()
            .domain([minScore + 1, maxScore - 1])
            .thresholds(d3.range(minScore + 1, maxScore))
            .value(d => d);

        const bins = histogram(scores);

        const y = d3.scaleLinear()
            .domain([0, d3.max(bins, d => d.length)])
            .range([dimensions.boundedHeight, 0]);

        // Add bars with transition
        bounds.selectAll("rect")
            .data(bins)
            .join("rect")
            .attr("x", d => x(d.x0) - barWidth/2)
            .attr("width", barWidth)
            .attr("y", d => y(d.length))
            .attr("height", d => dimensions.boundedHeight - y(d.length))
            .attr("fill", "#62A87C")
            .attr("opacity", 0.7);

        // Calculate flag position
        const flagPoleTop = 0;
        
        // Add flag pole
        bounds.append("line")
            .attr("x1", x(averageRound))
            .attr("x2", x(averageRound))
            .attr("y1", dimensions.boundedHeight)
            .attr("y2", flagPoleTop)
            .attr("stroke", "red")
            .attr("stroke-width", 2);

        // Add circular flag base
        bounds.append("circle")
            .attr("cx", x(averageRound))
            .attr("cy", dimensions.boundedHeight)
            .attr("r", 3)
            .attr("fill", "red");

        // Add flag
        const flagWidth = 15;
        const flagHeight = 10;
        bounds.append("path")
            .attr("d", `M ${x(averageRound) - 1} ${flagPoleTop}
                       L ${x(averageRound) - 1} ${flagPoleTop + flagHeight}
                       L ${x(averageRound) - 1 - flagWidth} ${flagPoleTop + flagHeight/2}
                       L ${x(averageRound) - 1} ${flagPoleTop}`)
            .attr("fill", "red");

        // Add axes with centered ticks and maximum count
        const totalTicks = maxScore - minScore - 1;
        const maxTickCount = 15;
        const tickStep = Math.ceil(totalTicks / maxTickCount);
        
        const xAxis = d3.axisBottom(x)
            .tickValues(
                d3.range(minScore + 1, maxScore)
                    .filter(d => d % tickStep === 0)
            )
            .tickFormat(d => Math.round(d));

        const yAxis = d3.axisLeft(y)
            .ticks(5);

        bounds.append("g")
            .attr("transform", `translate(0,${dimensions.boundedHeight})`)
            .call(xAxis);

        bounds.append("g")
            .call(yAxis);

    }, [scores, averageRound, dimensions]);

    return (
        <div className="flex flex-col items-center mt-8 w-full" ref={ref}>
            <H2Heading>Score Distribution</H2Heading>
            <ChartCanvas dimensions={dimensions}>
                <g className="bounds" />
            </ChartCanvas>
        </div>
    );
};


export const GolfRound = () => {
    const [averageRound, updateAverageRound] = useState(72);
    const [distributionScore, updateDistributionScore] = useState(5);
    const [roundScores, updateRoundScores] = useState(null);
    const [groupScores, updateGroupScores] = useState(null);
    const colourScale = useRef(null);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const roundScores = simulateGolfDistribution(averageRound, distributionScore);
            const roundInfo = groupAndCountScores(roundScores);
            const percentages = roundInfo.map(x => x.percentage);

            updateRoundScores(roundScores);
            updateGroupScores(roundInfo);

            colourScale.current = d3.scaleSequential()
                .domain([0, d3.max(percentages)])
                .interpolator(d3.interpolateRgb('white', '#62A87C'));
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [averageRound, distributionScore]);

    if (roundScores === null) {
        return <Loading></Loading>
    }

    return (
        <PageBody>
            <Helmet>
                <title>Predicting Golf Results | VizBadger</title>
                <meta
                    name="description"
                    content="Predict the outcome of a golf event using monte carlo simulations and modeling a round of golf programmatically. Apply statistics and knowledge of players to calculate the likelihood of things happening and results."
                ></meta>
                <meta name="keywords" content="Sports betting, Betting accumulators, Betting exchanges, Betting value, Accumulators, Betting guide, Betting UK, Football betting, Betting accumulator strategy, Betting value strategy, Betting odds value, Betting value bets, Value betting strategy"></meta>
            </Helmet>

            <GolfModelsHomeButton />

            <ArticleTitle title="Predicting Golf Results" />

            <ArticleParagraph
                lines={[
                    "At the very simplest level, you have a golfer that has an average score. Beyond that, they will vary in their scores; some will be more consistent than others, some will have a lower best score than their competitors.",
                    "Using Monte Carlo simulation, you can input some very simple parameters for each golfer, and see their distribution of scores. Take the example below of a golfer that has an average score of 72 (par), and a distribution of shots of 5.",
                    "This simulation naively assumes that the distribution is normal but, as I said, this is the simplest terms you can view a round of golf for simulation. With more detailed golf statistics, and accompanied with course and weather details, you could better estimate a golfer's chance. Whether this would be good enough to find a betting edge is hard to tell but it may provide insight into the player themselves.",
                ]}
            />

            <H2Heading>Player Statistics</H2Heading>
            <ArticleParagraph
                lines={[
                    "Adjust the player statistics below to simulate a new set of rounds and see the distribution of scores.",
                ]}
            />

            <MySliderComponent
                averageRound={averageRound}
                updateAverageRound={updateAverageRound}
                distributionScore={distributionScore}
                updateDistributionScore={updateDistributionScore}
            />
            <div className="flex flex-col-reverse lg:flex-row gap-8">
                <div className="lg:w-1/3">
                    <ScoreDistributionTable scoreInfo={groupScores} colourScale={colourScale} />
                </div>
                <div className="lg:w-2/3">
                    <ScoreHistogram scores={roundScores} averageRound={averageRound} />
                </div>
            </div>
        </PageBody>
    );
};
