import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';
import { ArticleParagraph } from "../../../components/Paragraphs";
import { useEffect, useState } from "react";
import { generatePoolPlayers } from "./utility/poolPlayerCreation";
import { Loading } from "../../../components/Loading";
import { simulateLeagueFixtures, simulateNLeagues } from "./utility/simulateLeague";
import { PlayerAttributesTable } from "./components/PlayerAttributesTable";
import { generateNRoundRobinPairings } from "./utility/fixtureGeneration";
import { FixturesDisplay } from "./components/FixturesDisplay";
import { displayPercentage } from "../../../helpers";
import { PositionChancesChart } from "./components/PositionChancesChart";


const SimulationControls = ({ nPlayers, setNPlayers, nRounds, setNRounds, nSimulations, setNSimulations }) => {
    return (
        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center mb-6">
            <div className="flex items-center gap-2">
                <label htmlFor="nPlayers">Players:</label>
                <select 
                    id="nPlayers"
                    value={nPlayers}
                    onChange={(e) => setNPlayers(parseInt(e.target.value))}
                    className="border rounded px-2 py-1"
                >
                    {[2,3,4,5,6,7,8,9,10].map(n => (
                        <option key={n} value={n}>{n}</option>
                    ))}
                </select>
            </div>
            
            <div className="flex items-center gap-2">
                <label htmlFor="nRounds">Rounds:</label>
                <select 
                    id="nRounds"
                    value={nRounds}
                    onChange={(e) => setNRounds(parseInt(e.target.value))}
                    className="border rounded px-2 py-1"
                >
                    {[1,2,3,4,5,6].map(n => (
                        <option key={n} value={n}>{n}</option>
                    ))}
                </select>
            </div>

            <div className="flex items-center gap-2">
                <label htmlFor="nSimulations">Simulations:</label>
                <select 
                    id="nSimulations"
                    value={nSimulations}
                    onChange={(e) => setNSimulations(parseInt(e.target.value))}
                    className="border rounded px-2 py-1"
                >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={500}>500</option>  
                    <option value={750}>750</option>
                </select>
            </div>

        </div>
    );
};


export const CustomPoolLeagueSimulation = () => {
    const [randomPlayers, setRandomPlayers] = useState(null);
    const [nPlayers, setNPlayers] = useState(6);
    const [nRounds, setNRounds] = useState(2);
    const [nSimulations, setNSimulations] = useState(100);
    const [fixtures, setFixtures] = useState(null);
    const [results, setResults] = useState(null);
    const [multipleLeagueSimulations, setMultipleLeagueSimulations] = useState([]);

    useEffect(() => {
        // Generate players, fixtures, and simulate the league each time the user changes the number of players, rounds, or simulations
        const players = generatePoolPlayers(nPlayers);
        const fixtures = generateNRoundRobinPairings(players, nRounds);
        const simulationResults = simulateLeagueFixtures(players, fixtures);
        const multipleLeagueSimulationResults = simulateNLeagues(players, fixtures, nSimulations);

        setRandomPlayers(players);
        setFixtures(fixtures);
        setResults(simulationResults.table);
        setMultipleLeagueSimulations(multipleLeagueSimulationResults);
    }, [nPlayers, nRounds, nSimulations]);


    if (randomPlayers === null | fixtures === null | results === null) {
        return <Loading />
    };

    return (
        <PageBody>
            <Helmet>

                <title>Custom English Pool League Simulation | VizBadger</title>
                <meta name="description" content="Customize and simulate English Pool leagues with different numbers of players and rounds using Monte Carlo simulations to predict outcomes." />
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool simulation customization, monte carlo simulations" />
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/custom-league-predictions" />
            </Helmet>

            <PoolSimulationHomeButton />

            <ArticleTitle title="Custom League Simulation" />

            <ArticleParagraph
                lines={[
                    "Customize your league simulation by selecting the number of players, rounds per matchup, and total simulations to run. This will help you understand how different league structures might affect outcomes.",
                ]}
            />

            <SimulationControls 
                nPlayers={nPlayers}
                setNPlayers={setNPlayers}
                nRounds={nRounds}
                setNRounds={setNRounds}
                nSimulations={nSimulations}
                setNSimulations={setNSimulations}
            />

            <H2Heading>Player Attributes</H2Heading>
            <PlayerAttributesTable players={randomPlayers} />

            <div className="my-8 w-full">
                <H2Heading>Multiple Simulation Results ({nSimulations} Simulations)</H2Heading>
                <div className="flex flex-row items-center justify-center text-xs sm:text-base">
                    <table className="w-full sm:w-4/5 text-sm sm:text-base rounded">

                    <thead className="text-center text-xs sm:text-base">
                        <tr className="tracking-tight italic font-semibold bg-gray-100">
                            <td className="p-1 px-2">Player</td>
                            <td className="p-1 px-2">Matches per Simulation</td>
                            <td className="p-1 px-2 hidden sm:table-cell">Avg. Points</td>
                            <td className="p-1 px-2">Avg. Wins</td>
                            <td className="p-1 px-2">Avg. Score Diff</td>
                            <td className="p-1 px-2 sm:hidden">% - 1st/{nPlayers}{nPlayers === 2 ? 'nd' : nPlayers === 3 ? 'rd' : 'th'}</td>
                            <td className="p-1 px-2 hidden sm:table-cell">1st Chance</td>
                            <td className="p-1 px-2 hidden sm:table-cell">Last Chance</td>
                            <td className="p-1 px-2">Positional Chances <br></br>(1st &rarr; {nPlayers}{nPlayers === 2 ? 'nd' : nPlayers === 3 ? 'rd' : 'th'})</td>
                        </tr>
                    </thead>
                    <tbody className="text-center text-xs sm:text-base">
                        {multipleLeagueSimulations.map((player) => (
                            <tr key={player.player_id}>
                                <td className="p-1 border-b border-gray-100 border-l">{player.player_id}</td>
                                <td className="p-1 border-b border-gray-100">{player.averageGamesPlayed.toFixed(0)}</td>
                                <td className="p-1 border-b border-gray-100 hidden sm:table-cell">{player.averagePoints.toFixed(1)}</td>

                                <td className="p-1 border-b border-gray-100">{player.averageWins.toFixed(1)}</td>

                                <td className="p-1 border-b border-gray-100">{player.averageScoreDifference > 0 ? '+' : ''}{player.averageScoreDifference.toFixed(1)}</td>
                                <td className="p-1 border-b border-gray-100 sm:hidden">{displayPercentage(player.firstPlaceChance, 2)}/{displayPercentage(player.lastPlaceChance, 2)}</td>
                                <td className="p-1 border-b border-gray-100 hidden sm:table-cell">{displayPercentage(player.firstPlaceChance, 2)}</td>
                                <td className="p-1 border-b border-gray-100 border-r hidden sm:table-cell">{displayPercentage(player.lastPlaceChance, 2)}</td>


                                <td className="flex justify-center items-center">
                                    <PositionChancesChart chances={player.positionChances} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>


            <H2Heading>Fixtures</H2Heading>
            <FixturesDisplay fixtures={fixtures} />

            <H2Heading>Single Simulation Results</H2Heading>
            <div className="flex flex-row items-center justify-center mb-5">
                <table className="w-full sm:w-1/2 text-xs sm:text-base rounded">
                    <thead className="text-center">
                        <tr className="tracking-tight italic font-semibold bg-gray-100">
                            <td className="py-1 px-3">#</td>
                            <td className="p-1">Player</td>
                            <td className="p-1">Played</td>
                            <td className="p-1">Wins</td>
                            <td className="p-1">Score For</td>
                            <td className="p-1">Score Against</td>
                            <td className="p-1">Score Diff</td>
                            <td className="p-1">Points</td>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {results.map((player_result, i) => (
                            <tr key={player_result.player_id}>
                                <td className="py-1 border-r border-gray-100">{i + 1}</td>
                                <td className="py-1">{player_result.player_id}</td>
                                <td className="py-1">{player_result.played}</td>
                                <td className="py-1">{player_result.wins}</td>
                                <td className="py-1">{player_result.score_for}</td>
                                <td className="py-1">{player_result.score_against}</td>
                                <td className="py-1">{player_result.score_difference}</td>
                                <td className="py-1">{player_result.points}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </PageBody>
    );
}; 
