import { Cog6ToothIcon, FireIcon, BoltIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { BatchCarousel } from "../../../components/BatchCarousel";
import { H2Heading, ArticleTitle } from "../../../components/Headers";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';
import { Loading } from "../../../components/Loading";
import { PageBody } from "../../../components/PageBody";
import { displayPercentage } from "../../../helpers";
import { basePlayerAttributes } from "./utility/basePlayerAttributes"; // Return to shared resource if required
import { batchSimulateBreakGame } from "./utility/batchSimulateGames";
import { Accordion } from "./components/Accordion";
import { GameShotSummary } from "./components/GameShotSummary";
import { PlayerAttributes } from "./components/PlayerAttributes";
import { QuoteBlock } from "./components/QuoteBlock";
import { ShotByShotReport } from "./components/ShotByShotReport";
import { Histogram } from "./graphs/Histogram";
import { ViolinDistribution } from "./graphs/ViolinDistribution";
import { takeShot, updateScore } from "./utility/matchActions";


const median = arr => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};


const simulateGame = (player1Attributes, player2Attributes, startingPlayer) => {
    let gameShots = [];
    let shootingPlayer = startingPlayer;
    let player1Score = 0;
    let player2Score = 0;
    let lastShotOutcome = false;
    let breakSplitBenefit = 0;
    let breakSplitDuration = 0;
    let nextShotAccuracy = 0;
    let breakOutcome = null;

    // Break phase
    if (shootingPlayer === 'player1') {
        lastShotOutcome = takeShot(player1Attributes['break']['make']);
        player1Score = updateScore(player1Score, lastShotOutcome); // Update the score if they made the break

        gameShots.push([shootingPlayer, lastShotOutcome, player1Score + " - " + player2Score, player1Attributes['break']['make'], true, false]);
        breakSplitBenefit += player1Attributes['break']['splitBenefit'];
        breakSplitDuration += player1Attributes['break']['splitDuration']; // Increment the split post-break
    } else {
        lastShotOutcome = takeShot(player2Attributes['break']['make']);
        player2Score = updateScore(player2Score, lastShotOutcome); // Update the score if they made the break

        gameShots.push([shootingPlayer, lastShotOutcome, player1Score + " - " + player2Score, player2Attributes['break']['make'], true, false]);
        breakSplitBenefit += player2Attributes['break']['splitBenefit'];
        breakSplitDuration += player2Attributes['break']['splitDuration'];
    };

    // Store break outcome
    breakOutcome = lastShotOutcome;

    // Swap the shooting player if they missed their break
    if (!lastShotOutcome) {
        if (shootingPlayer === 'player1') {
            shootingPlayer = 'player2'
        } else {
            shootingPlayer = 'player1'
        };
    };

    // While neither player has won, continue playing
    while (player1Score < 10 & player2Score < 10) {
        nextShotAccuracy = 0;

        if (breakSplitDuration > 0) {
            nextShotAccuracy += breakSplitBenefit
        };

        if (shootingPlayer === 'player1') {
            nextShotAccuracy += player1Attributes['regular']['make'];
        } else {
            nextShotAccuracy += player2Attributes['regular']['make'];
        };

        // Take the shot
        lastShotOutcome = takeShot(nextShotAccuracy);

        if (shootingPlayer === 'player1') {
            player1Score = updateScore(player1Score, lastShotOutcome);
        } else {
            player2Score = updateScore(player2Score, lastShotOutcome);
        };

        // Improve shot structure?
        gameShots.push([shootingPlayer, lastShotOutcome, player1Score + " - " + player2Score, nextShotAccuracy, false, breakSplitDuration > 0 ? true : false]);

        // Swap the shooting player if they missed
        if (!lastShotOutcome) {
            if (shootingPlayer === 'player1') {
                shootingPlayer = 'player2'
            } else {
                shootingPlayer = 'player1'
            };
        };

        // The break benefit depletes
        breakSplitDuration -= 1;
    };

    // We could take top streaks here and store that information as well
    let player1TopStreak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player1')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // If n is true, increment the final position by one
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    let player2TopStreak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player2')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // If n is true, increment the final position by one
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    const output = {
        'score': player1Score + " - " + player2Score,
        'winner': player1Score === 10 ? 'player1' : 'player2',
        'breakPlayer': startingPlayer,
        'breakOutcome': breakOutcome,
        'shots': gameShots,
        'totalShots': gameShots.length,
        'player1Score': player1Score,
        'player1Shots': gameShots.filter(shot => shot[0] === 'player1').length,
        'player1ShotsMade': gameShots.filter(shot => shot[0] === 'player1' & shot[1]).length,
        'player1TopStreak': player1TopStreak,
        'player2Score': player2Score,
        'player2Shots': gameShots.filter(shot => shot[0] === 'player2').length,
        'player2ShotsMade': gameShots.filter(shot => shot[0] === 'player2' & shot[1]).length,
        'player2TopStreak': player2TopStreak,
    };

    return output;
};


export const BreakSimulation = () => {
    const batchSize = 850;
    const [playerAttributes, updatePlayerAttributes] = useState({...basePlayerAttributes});
    const [simulatedGame, updateSimulatedGame] = useState(null);
    const [batchSimulations, updateBatchSimulations] = useState(null);

    const defaultPlayer1Accuracy = 50;
    const defaultPlayer2Accuracy = 40;

    const runBatchSimulations = () => {
        updateBatchSimulations(batchSimulateBreakGame(simulateGame, batchSize, playerAttributes));
    };

    const runSingleSimulation = () => {
        updateSimulatedGame(simulateGame(playerAttributes['player1'], playerAttributes['player2'], Math.random() < 0.5 ? 'player1' : 'player2'));
    };

    // Need to pass a whole new set of attributes here
	const adjustPlayerAttributes = (player, newAttributes) => {
		playerAttributes[player] = newAttributes;
		updatePlayerAttributes({...playerAttributes});

		runSingleSimulation();
		runBatchSimulations();
	};

    const updatePlayerAccuracies = (player, newAccuracy, player2, newAccuracy2) => {
        playerAttributes[player]['regular']['make'] = newAccuracy / 100;
        playerAttributes[player2]['regular']['make'] = newAccuracy2 / 100;
        updatePlayerAttributes({ ...playerAttributes });

        runSingleSimulation();
        runBatchSimulations();
    };

    // Fill on first mount
    useEffect(() => {
        updatePlayerAccuracies('player1', defaultPlayer1Accuracy, 'player2', defaultPlayer2Accuracy);
        updateSimulatedGame(simulateGame(playerAttributes['player1'], playerAttributes['player2'], Math.random() < 0.5 ? 'player1' : 'player2'));
        updateBatchSimulations(batchSimulateBreakGame(simulateGame, batchSize, playerAttributes));
        // eslint-disable-next-line
    }, []);


    if (simulatedGame === null | batchSimulations === null) {
        return <Loading></Loading>
    };

    return (
        <PageBody>
            <Helmet>
                <title>English Pool Monte Carlo Simulation - Break Efficiency | VizBadger</title>
                <meta name="description" content="Simulate pool games using shot accuracy and break efficiency to produce expected results given player skill levels."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/break-efficiency" />
            </Helmet>

            <PoolSimulationHomeButton />
            <ArticleTitle title="Stage 2: Break Efficiency" />
            <QuoteBlock quoteText="A strong, effective break gives one player a clear advantage over the other." />

            <div className="text-sm sm:text-base text-center sm:text-left">
                <p className="mb-2 sm:mb-0"><Link to="/sports-models/english-pool-monte-carlo-simulation/shot-accuracy" className="text-badger-blue font-bold hover:cursor-pointer hover:underline">Stage 1</Link> made the assumption that all shots are equal but this isn't reality. Non more so than the first shot of the game, the break (<BoltIcon className="h-4 w-4 text-badger-yellow inline-block" />). All shots are different and unique, but introducing the break with its own attributes and skills seems a first obvious step. A break is judged by two clear attributes in my opinion:</p>
                <div className="flex flex-col items-center justify-center mx-8 mb-2">
                    <ol className="list-decimal text-left mx-4 my-3">
                        <li><span className="font-bold">Making</span> a ball (and getting the first visit to the table)</li>
                        <li>The subsequent <span className="font-bold">split</span> of the remaining balls</li>
                    </ol>
                </div>
                <p className="my-2">Implementing takes two sections really, a 'make chance' (%), and then a split factor will impact your chance of making the next shot/s <span className="italic">(shown as a + below)</span>. Players' have their own strengths in all areas of the game, and now there are more variables to simulate and more to consider in the analysis.</p>
                <p className="my-2">Now being good at the break can give you an early advantage, but does this translate better results?</p>
            </div>
            <hr className="my-4" />
            <div className="text-sm sm:text-base text-center sm:text-left">
                <H2Heading>Player Attributes</H2Heading>
                <p className="my-2">The players' games become more bespoke with more complexity, nuances that build a player's archetype as a player. 'Consistency is key' vs. 'Volatile brilliantly breaking'.</p>
                <PlayerAttributes
                    playerAttributes={playerAttributes}
                    playerNames={["Player 1", "Player 2"]}
                    playerKeys={["player1", "player2"]}
                    includeFoul={false}
                    updatePlayerAttributes={adjustPlayerAttributes}
                />
            </div>
            <div className="text-sm sm:text-base text-center sm:text-left">
                <p className="mb-2">I've created those player archetypes in code form, a huge break but less effective when it comes to regular shot making. They smash it, generally make a ball and put the remaining balls in great positions, essentially guaranteeing a break at the beginning of the game.</p>
                <p>Simulate games and observe the benefit that a large break provides, although it doesn't necessarily guarantee a win.</p>
            </div>
            <hr className="my-4" />
            <div className="flex flex-col items-center justify-center relative">
                <div className="flex items-center justify-center sticky top-18">
                    <button
                        className="text-base my-2 flex items-center justify-center bg-badger-green font-bold text-white py-1 px-2 rounded-lg"
                        onClick={() => runSingleSimulation()}
                    >
                        <Cog6ToothIcon className="h-6 w-6 mr-1" />
                        Simulate A Single Game
                    </button>
                </div>
                <GameShotSummary simulatedGame={simulatedGame} />
                <div className="flex items-center justify-center flex-col text-sm sm:text-base text-center sm:text-left mt-4">
                    <H2Heading>Shot by Shot Report</H2Heading>
                    <ShotByShotReport
                        simulatedGame={simulatedGame}
                        highlightBreakBenefit={true}
                        showBreakPlayer={true}
                    />
                </div>
            </div>

            <Accordion
                title={`Batch Simulate ${batchSize} Games`}
                defaultActive={true}
            >
                <div className="relative">
                    <div className="flex items-center justify-center sticky top-18 pt-2">
                        <button
                            className="text-base my-2 flex items-center justify-center bg-badger-purple font-bold text-white py-1 px-2 rounded-lg"
                            onClick={() => runBatchSimulations()}
                        >
                            <Cog6ToothIcon className="h-6 w-6 mr-1" />
                            Simulate {batchSize} Games
                        </button>
                    </div>

                    <div className="w-full flex flex-col items-center justify-center mb-4">
                        <p className="text-base sm:text-lg">Overall Wins</p>
                        <p className="text-sm sm:text-base text-center sm:text-left">Player 1: {batchSimulations.playerWins.player1}</p>
                        <p className="text-sm sm:text-base text-center sm:text-left">Player 2: {batchSimulations.playerWins.player2}</p>
                    </div>

                    <div className="w-full flex flex-col items-center justify-center mb-8">
                        <H2Heading>Player Accuracy Distributions</H2Heading>
                        <p className="mb-2 text-sm sm:text-base text-center sm:text-left">We know each player's observed accuracies (shown on graph as - - - -), but what is the chance of them achieving that on any given game? Below is each players accuracy in a given game across all the simulations. You see that most games hover around the observed accuracy, but the variation in performances for a given game is clear to see.</p>
                        <ViolinDistribution
                            chartSettings={{ height: 300, marginLeft: 30, marginBottom: 60 }}
                            playerAccuracies={batchSimulations.playerAccuracies}
                            player1Accuracy={playerAttributes['player1']['regular']['make'] * 100}
                            player2Accuracy={playerAttributes['player2']['regular']['make'] * 100}
                        />
                    </div>

                    <div className="break-words mb-8">
                        <H2Heading>Game Lengths (shots)</H2Heading>
                        <Histogram
                            chartSettings={{height: 240, marginTop: 0, marginBottom: 60, marginRight: 0, marginLeft: 45}}
                            dataArray={batchSimulations.gameLengths}
                            filteredDataArray={batchSimulations.gameLengths}
                            xLabel="Game Length (total shots)"
                            medianValue={median(batchSimulations.gameLengths)}
                        />
                    </div>

                    <div>
                        <div className="mb-2">
                            <H2Heading><BoltIcon className="h-6 w-6 text-badger-yellow" />&nbsp;Player Break Conversion</H2Heading>
                            <p className="flex items-center justify-center text-sm sm:text-base text-center md:text-left mt-2">Now with a break element to the game, do we see a difference? Does one player rely on their break?</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-center">
                            <div className="flex flex-wrap items-center justify-center mb-2">
                                <div
                                    className="flex flex-col text-center my-2"
                                >
                                    <div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">Winner</div>
                                    <div className="flex justify-center items-center h-8 px-2 italic border-r">P1</div>
                                    <div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-b border-r pb-2">Win %</div>
                                    <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">P2</div>
                                    <div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-r">Win %</div>
                                </div>
                            </div>

                            <div className="flex flex-wrap items-center justify-center mb-2">
                                <div
                                    className="flex flex-col text-center my-2"
                                >
                                    <div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">Break</div>
                                    <div className="flex justify-center items-center h-8 px-2 italic border-r flex">{batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer === 'player1').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer === 'player1').length}</span></div>
                                    <div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-b border-r pb-2">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer === 'player1').length / batchSimulations.simulations.filter(x => x.breakPlayer === 'player1').length)}</div>
                                    <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer === 'player2').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer === 'player2').length}</span></div>
                                    <div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-r">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer === 'player2').length / batchSimulations.simulations.filter(x => x.breakPlayer === 'player2').length)}</div>
                                </div>
                            </div>
                            <div className="flex flex-wrap items-center justify-center mb-2">
                                <div
                                    className="flex flex-col text-center my-2"
                                >
                                    <div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center">Didn't Break</div>
                                    <div className="flex justify-center items-center h-8 px-2 italic">{batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer !== 'player1').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer !== 'player1').length}</span></div>
                                    <div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-b pb-2">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer !== 'player1').length / batchSimulations.simulations.filter(x => x.breakPlayer !== 'player1').length)}</div>
                                    <div className="flex justify-center items-center h-8 px-2 italic pt-2">{batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer !== 'player2').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer !== 'player2').length}</span></div>
                                    <div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer !== 'player2').length / batchSimulations.simulations.filter(x => x.breakPlayer !== 'player2').length)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-6">
                            <p className="flex items-center justify-center text-sm sm:text-base text-center md:text-left mt-2">Note here the benefit that breaks have in general, and the percentage of games that are won by the player that breaks. If your break is exceptional, it doesn't necessarily make up for your late game. Keep simluating and see the benefit that the player who breaks gets. If your break improves your position most of the time, then it benefits you. If you cannot win directly from your good break then improved overall accuracy allows for players to stage a comeback.</p>
                        </div>
                    </div>
                    <div>
                        <div className="mb-2">
                            <H2Heading><FireIcon className="h-6 w-6 text-badger-orange" />&nbsp;Top Streaks</H2Heading>
                            <p className="flex items-center justify-center text-sm sm:text-base text-center md:text-left mt-2">You can see in how many matches a player managed to put together a long streak. A slight shift in accuracy allows a player to avoid 'low streak' games and hugely increases the chance of them getting at least a streak of 4 at some point in the game.</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-center mb-8">
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 w-8 text-sm italic text-gray-500 text-center border-r">-</div>
                                <div className="flex justify-center items-center h-8 w-8 italic border-r">P1</div>
                                <div className="flex justify-center items-center h-8 w-8 text-xs text-gray-500 italic border-b border-r pb-2">%</div>
                                <div className="flex justify-center items-center h-8 w-8 italic border-r pt-2">P2</div>
                                <div className="flex justify-center items-center h-8 w-8 text-xs text-gray-500 italic border-r">%</div>
                            </div>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((shot, i) =>
                                <div
                                    className="flex flex-col text-center my-2"
                                    key={i}
                                >
                                    <div className="h-8 w-8 flex justify-center items-center text-sm italic text-gray-500">{i}</div>
                                    <div className="h-8 w-8 flex justify-center items-center text-sm">{batchSimulations.simulations.filter(simul => simul.player1TopStreak === i).length}</div>
                                    <div className="h-8 w-8 flex justify-center text-xs italic text-gray-500 items-center border-b pb-2">{displayPercentage(batchSimulations.simulations.filter(simul => simul.player1TopStreak === i).length / batchSimulations.simulations.length, false)}</div>
                                    <div className="h-8 w-8 flex justify-center items-center text-sm pt-2">{batchSimulations.simulations.filter(simul => simul.player2TopStreak === i).length}</div>
                                    <div className="h-8 w-8 flex justify-center text-xs italic text-gray-500 items-center">{displayPercentage(batchSimulations.simulations.filter(simul => simul.player2TopStreak === i).length / batchSimulations.simulations.length, false)}</div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-8 text-center">
                        <H2Heading>Batch Simulation Results</H2Heading>
                        <p className="text-sm sm:text-base text-center sm:text-left mb-2">See a match summary of the first 50 simulated games.</p>
                        <BatchCarousel
                            slides={[...Array(batchSimulations.simulations.length).keys()].slice(0, 50)}
                            content={batchSimulations.simulations}
                        />
                    </div>
                </div>
            </Accordion>
        </PageBody>
    );
};
