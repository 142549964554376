// This file contains the functions for simulating a game between two players

// Match helpers
// Given a player, return the opponent
const opponentPlayer = (player) => {
    return player === 'player_1' ? 'player_2' : 'player_1';
};


// scores = {'player_1': int, 'player_2': int}
export const updateScores = (
    scores,
    shootingPlayer,
    lastShotOutcome,
    lastShotFoul,
    lastShotFoulMadeBlack

) => {
    // The shot was made
    if (lastShotOutcome) {
        // On the black and foul
        if (scores[shootingPlayer] === 7 && lastShotFoul) {
            scores[opponentPlayer(shootingPlayer)] = 10;
        } else {
            // Increase score
            scores[shootingPlayer] += scores[shootingPlayer] === 7 ? 3 : 1;
        };
    };

    // Losing the game on foul
    if (lastShotFoul && lastShotFoulMadeBlack) {
        scores[opponentPlayer(shootingPlayer)] = 10;
    };

    return scores;
};


// [shootingPlayer, tableVisits]
export const handleVisits = (
    shootingPlayer,
    playerTableVisits,
    lastShotOutcome,
    lastShotFoul
) => {
    // Give two visits to opponent if shooting player fouled
    if (lastShotFoul) {
        return [opponentPlayer(shootingPlayer), 2];
    };

    // If this shot was the first post-foul
    if (playerTableVisits === 2) {
        return [shootingPlayer, 1];
    };

    // Continue the visit if the shot was made
    if (lastShotOutcome) {
        return [shootingPlayer, 1];
    };

    // Else swap player at the table
    return [opponentPlayer(shootingPlayer), 1];
};


// Simulate a single shot with foul chance
const takeShotFoul = (accuracyPercentage, foulPercentage, foulMakeBlackPercentage) => {
    // Outcome = [madeShot, wasFoul, madeBlack]
    let shotOutcome = [false, false, false];

    // Does the player make the ball?
    if (Math.random() < accuracyPercentage) {
        shotOutcome[0] = true;
    };

    // Does the player foul?
    if (Math.random() < foulPercentage) {
        shotOutcome[1] = true;

        // Player has fouled, did they make the black as well?
        if (Math.random() < foulMakeBlackPercentage) {
            shotOutcome[2] = true;
        }
    };

    return shotOutcome;
};


// Give attributes object structure, simulate full game
export const simulateGame = (playerAttributes, startingPlayer) => {
    // Store the shots of the entire game
    let gameShots = [];

    // Track how many visits the player has at the table
    let playerTableVisits = 1;
    let shootingPlayer = startingPlayer;

    // Track scores
    let scores = { 'player_1': 0, 'player_2': 0 };

    // Last shot outcome
    let lastShotOutcome = false;
    let lastShotFoul = false;
    let lastShotFoulMadeBlack = false;

    // Break
    let breakSplitBenefit = 0;
    let breakSplitDuration = 0;
    let breakOutcome = null;

    // Track accuracy changes
    let nextShotAccuracy = 0;
    // Break
    [lastShotOutcome, lastShotFoul, lastShotFoulMadeBlack] = takeShotFoul(
        playerAttributes[shootingPlayer]['break']['make'],
        playerAttributes[shootingPlayer]['break']['foul'],
        0
    );

    scores = updateScores(
        scores,
        shootingPlayer,
        lastShotOutcome,
        lastShotFoul,
        lastShotFoulMadeBlack
    );

    // Store break outcome
    breakOutcome = lastShotOutcome;

    // Push shot details to game shots
    gameShots.push([
        shootingPlayer,
        lastShotOutcome,
        scores['player_1'],
        scores['player_2'],
        playerAttributes[shootingPlayer]['break']['make'],
        true,
        breakSplitBenefit,
        lastShotFoul,
        lastShotFoulMadeBlack
    ]);

    // Update break benefits
    breakSplitBenefit += playerAttributes[shootingPlayer]['break']['splitBenefit'];
    breakSplitDuration += playerAttributes[shootingPlayer]['break']['splitDuration'];

    // Calculate who should be playing, and how many visits they get
    [shootingPlayer, playerTableVisits] = handleVisits(
        shootingPlayer,
        playerTableVisits,
        lastShotOutcome,
        lastShotFoul
    );

    // While neither player has won, continue playing
    while (playerTableVisits > 0 & scores['player_1'] < 10 & scores['player_2'] < 10) {
        nextShotAccuracy = playerAttributes[shootingPlayer]['regular']['make'];
        nextShotAccuracy += breakSplitDuration > 0 ? breakSplitBenefit : 0;

        // Outcome
        [lastShotOutcome, lastShotFoul, lastShotFoulMadeBlack] = takeShotFoul(
            nextShotAccuracy,
            playerAttributes[shootingPlayer]['regular']['foul'],
            playerAttributes[shootingPlayer]['foul']['makeBlack']
        );

        // Update score - will handle immediate foul loss
        scores = updateScores(
            scores,
            shootingPlayer,
            lastShotOutcome,
            lastShotFoul,
            lastShotFoulMadeBlack
        );

        // Push result of shot, and resulting score
        gameShots.push([
            shootingPlayer,
            lastShotOutcome,
            scores['player_1'],
            scores['player_2'],
            playerAttributes[shootingPlayer]['regular']['make'],
            false, // isBreak
            breakSplitDuration > 0 ? breakSplitBenefit : 0, // splitBenefit
            lastShotFoul,
            lastShotFoulMadeBlack
        ]);

        // Calculate who should be playing, and how many visits they get
        [shootingPlayer, playerTableVisits] = handleVisits(
            shootingPlayer,
            playerTableVisits,
            lastShotOutcome,
            lastShotFoul
        );

        breakSplitDuration -= 1;
    };

    // We could take top streaks here and store that information as well
    let player_1_top_streak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player_1')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // If n is true, increment the final position by one
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    let player_2_top_streak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player_2')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    const output = {
        'player_ids': [playerAttributes['player_1']['player_id'], playerAttributes['player_2']['player_id']],
        'winner_id': scores['player_1'] === 10 ? playerAttributes['player_1']['player_id'] : playerAttributes['player_2']['player_id'],
        'loser_id': scores['player_1'] === 10 ? playerAttributes['player_2']['player_id'] : playerAttributes['player_1']['player_id'],

        [playerAttributes['player_1']['player_id']]: {
            'score': scores['player_1'],
            'score_against': scores['player_2']
        },

        [playerAttributes['player_2']['player_id']]: {
            'score': scores['player_2'],
            'score_against': scores['player_1']
        },

        'score': scores['player_1'] + " - " + scores['player_2'],
        'winner': scores['player_1'] === 10 ? 'player_1' : 'player_2',
        'break_player': startingPlayer,
        'break_player_id': playerAttributes[startingPlayer]['player_id'],
        'break_outcome': breakOutcome,
        'shots': gameShots,
        'total_shots': gameShots.length,
        'player_1_score': scores['player_1'],
        'player_1_shots': gameShots.filter(shot => shot[0] === 'player_1').length,
        'player_1_shots_made': gameShots.filter(shot => shot[0] === 'player_1' & shot[1]).length,
        'player_1_top_streak': player_1_top_streak,
        'player_1_fouls': gameShots.filter(shot => shot[0] === 'player_1' & shot[7]).length,
        'player_2_score': scores['player_2'],
        'player_2_shots': gameShots.filter(shot => shot[0] === 'player_2').length,
        'player_2_shots_made': gameShots.filter(shot => shot[0] === 'player_2' & shot[1]).length,
        'player_2_top_streak': player_2_top_streak,
        'player_2_fouls': gameShots.filter(shot => shot[0] === 'player_2' & shot[7]).length,
    };

    return output;
};