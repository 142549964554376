import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';
import { ArticleParagraph } from "../../../components/Paragraphs";
import { SinglePlayerAttributeDisplay } from "./components/PlayerAttributes";
import { useState } from "react";
import { basePlayerAttributes } from "./utility/basePlayerAttributes";
import { generatePoolPlayers } from "./utility/poolPlayerCreation";
import { displayPercentage } from "../../../helpers";


export const PlayerModel = () => {
    const [playerAttributes, updatePlayerAttributes] = useState(basePlayerAttributes);
    const [randomPlayers, setRandomPlayers] = useState(generatePoolPlayers(5));

    // Need to pass a whole new set of attributes here
    const adjustPlayerAttributes = (player, newAttributes) => {
        playerAttributes[player] = newAttributes;
        updatePlayerAttributes({ ...playerAttributes });
    };

    return (
        <PageBody>
            <Helmet>
                <title>English Pool Monte Carlo Simulation - Player Model | VizBadger</title>
                <meta name="description" content="Model out the key skills and attributes of a pool player to be used in future simulations. Keeping in mind statistics that could realistically be captured through watching real games and then applying statistical analysis to actual players."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators, athlete statistics"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/player-model" />
            </Helmet>

            <PoolSimulationHomeButton />

            <ArticleTitle title="Modelling a Player for Sports Simulations" />

            <H2Heading>English Pool Player</H2Heading>

            <ArticleParagraph lines={[
                "As part of building a sports model for english pool, we have to consider the two main elements of the event: the game rules and the players. Monte Carlo simulations allow for understanding the impact on the system of variable inputs; for pool this is the variation that we see at player level on the outcome of an event.",
                "Through the incremental tiered approach, we have settled on a player that has a number of attributes that represent their skill level. These are broken down into key areas of the game, potting in regular play, fouling, breaking and positional play (inferred through attributes around break building).",
                "The final result of this is the structure that you can see below. Throughout many of my simulations you are able to adjust the attributes of each player to see how that impacts a head to head game. I have tried to model attributes that you could realistically be calculated in real life so that it could be used for modelling real events using player statistics.",
            ]} />

            <div className="flex items-center justify-center">
                <SinglePlayerAttributeDisplay
                    key={playerAttributes['player1']}
                    playerAttributes={playerAttributes["player1"]}
                    playerName="Example Player"
                    playerKey="player1"
                    updatePlayerAttributes={adjustPlayerAttributes}
                />
            </div>

            <ArticleParagraph lines={[
                "Through random generation of players and simulating results we will be able to determine which attributes of a player have the greatest impact on their likelihood to win in certain conditions.",
                "In reality the skills would have correlation, but being completely random through multiple simulations will give us insight into the importance of skills.",
            ]} />

            <div className="text-sm sm:text-base text-center sm:text-left items-start">
                <H2Heading>Generating Multiple Players</H2Heading>
                <div className="w-full rounded mb-4">
                    <div className="text-center flex flex-row border-b">
                        <p className="w-1/3 border-r py-2">Players</p>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r border-t flex-1 flex items-center justify-center">{index + 1}</div>))}
                        </div>
                    </div>
                    <div className="text-center flex flex-row border-b">
                        <div className="w-1/3 border-r py-2 flex flex-row">
                            <p className="w-1/2">Regular Play</p>
                            <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Make Chance</p>
                        </div>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r flex-1 flex items-center justify-center">{displayPercentage(player['regular']['make'])}</div>))}
                        </div>
                    </div>
                    <div className="text-center flex flex-row">
                        <div className="w-1/3 border-r py-2 flex flex-row">
                            <p className="w-1/2">Break</p>
                            <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Make Chance</p>
                        </div>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['make'])}</div>))}
                        </div>
                    </div>
                    <div className="text-center flex flex-row">

                        <div className="w-1/3 border-r py-2 flex flex-row">
                            <p className="w-1/2"></p>
                            <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Split Benefit</p>
                        </div>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['splitBenefit'])}</div>))}
                        </div>
                    </div>
                    <div className="text-center flex flex-row border-b">

                        <div className="w-1/3 border-r py-2 flex flex-row">
                            <p className="w-1/2"></p>
                            <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Split Duration</p>
                        </div>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r flex-1 flex items-center justify-center">{player['break']['splitDuration']}</div>))}
                        </div>
                    </div>
                    <div className="text-center flex flex-row">

                        <div className="w-1/3 border-r py-2 flex flex-row">
                            <p className="w-1/2">Foul</p>
                            <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Regular Play</p>
                        </div>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['regular']['foul'])}</div>))}
                        </div>
                    </div>
                    <div className="text-center flex flex-row">

                        <div className="w-1/3 border-r py-2 flex flex-row">
                            <p className="w-1/2"></p>
                            <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Break</p>
                        </div>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['foul'])}</div>))}
                        </div>
                    </div>
                    <div className="text-center flex flex-row">

                        <div className="w-1/3 border-r py-2 flex flex-row">
                            <p className="w-1/2"></p>
                            <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Pot Black</p>
                        </div>
                        <div className="w-2/3 flex flex-row">
                            {randomPlayers.map((player, index) => (<div key={index} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['foul']['makeBlack'])}</div>))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3 flex justify-center items-center text-sm">
                <div
                    className="text-gray-400 border rounded-lg py-1 px-2 sm:border-gray-200 hover:shadow hover:cursor-pointer hover:border-badger-blue mr-3 hover:text-badger-blue"
                    onClick={() => setRandomPlayers(generatePoolPlayers(5))}
                >
                    Generate New Players
                </div>
            </div>
        </PageBody>
    );
};
