import { Cog6ToothIcon, FireIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { H2Heading, ArticleTitle } from "../../../components/Headers";
import { GameShotSummary } from "./components/GameShotSummary";
import { displayPercentage } from "../../../helpers";
import { takeShot, updateScore } from "./utility/matchActions";
import { ShotByShotReport } from "./components/ShotByShotReport";
import { QuoteBlock } from "./components/QuoteBlock";
import { Accordion } from "./components/Accordion";
import { Link } from "react-router-dom";
import { PageBody } from "../../../components/PageBody";
import { Loading } from "../../../components/Loading";
import { BatchCarousel } from "../../../components/BatchCarousel";
import { batchSimulateGame } from "./utility/batchSimulateGames";
import { Histogram } from "./graphs/Histogram";
import { ViolinDistribution } from "./graphs/ViolinDistribution";
import { basePlayerAttributes } from "./utility/basePlayerAttributes";
import { BatchAccuracySummary } from "./components/BatchAccuracySummary";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';


const median = arr => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};


const simulateGame = (player1Accuracy, player2Accuracy, startingPlayer) => {
    let gameShots = [];
    let shootingPlayer = startingPlayer;
    let player1Score = 0;
    let player2Score = 0;
    let lastShotOutcome = false;

    while (player1Score < 10 & player2Score < 10) {
        if (shootingPlayer === 'player1') {
            lastShotOutcome = takeShot(player1Accuracy);
            player1Score = updateScore(player1Score, lastShotOutcome);
        } else {
            lastShotOutcome = takeShot(player2Accuracy);
            player2Score = updateScore(player2Score, lastShotOutcome);;
        };

        // Improve shot structure?
        gameShots.push([shootingPlayer, lastShotOutcome, player1Score + " - " + player2Score]);

        // Swap the shooting player if they missed
        if (!lastShotOutcome) {
            if (shootingPlayer === 'player1') {
                shootingPlayer = 'player2'
            } else {
                shootingPlayer = 'player1'
            };
        };
    };

    // We could take top streaks here and store that information as well
    let player1TopStreak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player1')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // If n is true, increment the final position by one
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    let player2TopStreak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player2')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // If n is true, increment the final position by one
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    const output = {
        'score': player1Score + " - " + player2Score,
        'winner': player1Score === 10 ? 'player1' : 'player2',
        'breakPlayer': startingPlayer,
        'shots': gameShots,
        'totalShots': gameShots.length,
        'player1Score': player1Score,
        'player1Shots': gameShots.filter(shot => shot[0] === 'player1').length,
        'player1ShotsMade': gameShots.filter(shot => shot[0] === 'player1' & shot[1]).length,
        'player1TopStreak': player1TopStreak,
        'player2Score': player2Score,
        'player2Shots': gameShots.filter(shot => shot[0] === 'player2').length,
        'player2ShotsMade': gameShots.filter(shot => shot[0] === 'player2' & shot[1]).length,
        'player2TopStreak': player2TopStreak,
    };

    return output;
};


const PlayerAccuracySelector = ({ currentValue, inputOptions, updateFunction, player }) => {
    return <div className="flex flex-row border items-center justify-between rounded mx-4 my-2 cursor-pointer">
        {inputOptions.map(option =>
            <div
                className={`${option === currentValue ? "text-white bg-badger-green" : "hover:bg-gray-200"} px-3 py-1 w-full`}
                key={option}
                onClick={() => updateFunction(player, option)}
            >
                {option}%
            </div>
        )}
    </div>
};


export const AccuracySimulation = () => {
    const batchSize = 850;
    const [playerAttributes, updatePlayerAttributes] = useState({ ...basePlayerAttributes });
    const [simulatedGame, updateSimulatedGame] = useState(null);
    const [batchSimulations, updateBatchSimulations] = useState({});

    const updatePlayerAccuracy = (player, newAccuracy) => {
        playerAttributes[player]['regular']['make'] = newAccuracy / 100;
        updatePlayerAttributes({ ...playerAttributes });

        runSingleSimulation();
        runBatchSimulations();
    };

    // Fill on first mount
    useEffect(() => {
        updateSimulatedGame(simulateGame(playerAttributes['player1']['regular']['make'], playerAttributes['player2']['regular']['make'], 'player1'));
        updateBatchSimulations(batchSimulateGame(simulateGame, batchSize, playerAttributes));
        // eslint-disable-next-line
    }, []);


    const runBatchSimulations = () => {
        const simulatedBatch = batchSimulateGame(simulateGame, batchSize, playerAttributes);
        updateBatchSimulations(simulatedBatch);
    };

    const runSingleSimulation = () => {
        updateSimulatedGame(simulateGame(playerAttributes['player1']['regular']['make'], playerAttributes['player2']['regular']['make'], 'player1'));
    };

    if (simulatedGame === null | batchSimulations === null) {
        return <Loading></Loading>
    };

    return (
        <PageBody>
            <Helmet>
                <title>English Pool Monte Carlo Simulation - Shot Accuracy | VizBadger</title>
                <meta name="description" content="Simulate pool games using shot accuracy to produce expected results given player skill levels. Sports modelling using Monte Carlo simulations allow us to immediately see the impact of accuracy on a player's chance of winning."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/shot-accuracy" />
            </Helmet>

            <PoolSimulationHomeButton />
            <ArticleTitle title="Stage 1: Shot Accuracy" />
            <QuoteBlock quoteText="When two players play a game of pool, the more accurate player will win the game." />

            {/* <PlayerAttributes
                playerAttributes={playerAttributes}
                playerNames={["Player 1", "Player 2"]}
                playerKeys={["player1", "player2"]}
                includeBreak={false}
                includeFoul={false}
            /> */}
            <div className="flex flex-col items-center text-sm sm:text-base mb-4">
                <div className="text-center md:text-left w-full mb-4">
                    <p className="mb-2">A game of pool is a series of shots that are either potted or missed. Simpler still, I'll assume that each shot has the same chance of being made regardless of context or the position of other balls on the table.</p>
                    <p className="mb-3">The scoring convention I use is outlined in more detail in the <Link to="/sports-models/english-pool-monte-carlo-simulation/glossary" className="text-badger-blue font-bold hover:cursor-pointer hover:underline">glossary</Link>. Essentially, the winner gets 10 points and the loser gets a point for each colour that they have made i.e. a game that ends with both players on the black ball would end 10-7.</p>
                    <p className="italic text-xs text-gray-500">
                        Update the observed player accuracies to see the impact on the expected results through simulations.
                    </p>
                </div>
                <div className="flex flex-col text-center w-full max-w-xl border ml-0 mt-2 md:mt-0 md:ml-4 rounded-lg">
                    <p className="text-base sm:text-lg py-3 border-b">Observed Player Accuracies</p>
                    <div className="flex flex-col items-center justify-center h-full my-2">
                        <div className="w-full">
                            <p>Player 1</p>
                            <PlayerAccuracySelector
                                currentValue={playerAttributes['player1']['regular']['make'] * 100}
                                inputOptions={[30, 40, 50, 60, 70]}
                                updateFunction={updatePlayerAccuracy}
                                player="player1"
                            />
                        </div>
                        <div className="w-full mt-2">
                            <p>Player 2</p>
                            <PlayerAccuracySelector
                                currentValue={playerAttributes['player2']['regular']['make'] * 100}
                                inputOptions={[30, 40, 50, 60, 70]}
                                updateFunction={updatePlayerAccuracy}
                                player="player2"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Accordion
                title="Simulate A Single Game"
                defaultActive={true}
            >
                <div className="relative">
                    <div className="flex flex-col text-center md:text-left text-sm sm:text-base items-center justify-center mb-2">
                        <p className="w-full mb-2">
                            I can simulate a single game of pool using the known 'observed' player accuracies above. This gives us a final score, game accuracy percentage, and shot streaks for a one-off example game.
                        </p>
                        <GameShotSummary simulatedGame={simulatedGame} />
                    </div>
                    <div className="flex items-center justify-center sticky top-18">
                        <button
                            className="text-base my-2 flex items-center justify-center bg-badger-green font-bold text-white py-1 px-2 rounded-lg"
                            onClick={() => runSingleSimulation()}
                        >
                            <Cog6ToothIcon className="h-6 w-6 mr-1" />
                            Simulate A Single Game
                        </button>
                    </div>
                    <div className="flex items-center justify-center flex-col text-sm sm:text-base text-center sm:text-left mt-3">
                        <H2Heading>Shot by Shot Report</H2Heading>
                        <p className="mb-2">This breakdown shows us how the game unfolded with the outcome of each shot being shown. Try changing the player accuracies (effectively skill level) and see how each simulation changes.</p>
                        <ShotByShotReport simulatedGame={simulatedGame} />
                    </div>
                </div>
            </Accordion>

            <Accordion
                title={`Batch Simulate ${batchSize} Games`}
                defaultActive={true}
            >
                <div className="relative">
                    <div className="flex flex-col items-center justify-center text-sm sm:text-base text-center sm:text-left">
                        <p className="mb-2">
                            Simulate multiple games and see what trends appear when these players compete. Who wins more games? How often does the game go down to the wire? How likely is it that Player 1 will score at least 3 in a row?
                        </p>
                        <p className="mb-2">
                            The more simulations that you do, the 'smoother' the curve of the outcomes. You can then use these simulated results to find expected results for an individual game. See the results over {batchSize} games, we can now give an informed expected result. Player 1 will win {displayPercentage(batchSimulations.playerWins.player1 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2))} of the time for example.
                        </p>
                    </div>
                    <div className="flex items-center justify-center sticky top-18 pt-2">
                        <button
                            className="text-base my-2 flex items-center justify-center bg-badger-purple font-bold text-white py-1 px-2 rounded-lg"
                            onClick={() => runBatchSimulations()}
                        >
                            <Cog6ToothIcon className="h-6 w-6 mr-1" />
                            Simulate {batchSize} Games
                        </button>
                    </div>

                    <div className="w-full flex flex-col items-center justify-center mb-4">
                        <p className="text-base sm:text-lg">Overall Wins</p>
                        <p className="text-sm sm:text-base text-center sm:text-left">Player 1: {batchSimulations.playerWins.player1}</p>
                        <p className="text-sm sm:text-base text-center sm:text-left">Player 2: {batchSimulations.playerWins.player2}</p>
                    </div>

                    <div className="w-full flex flex-col items-center justify-center mb-8">
                        <H2Heading>Player Accuracy Distributions</H2Heading>
                        <p className="mb-2 text-sm sm:text-base text-center sm:text-left">We know each player's observed accuracies (shown on graph as - - - -), but what is the chance of them achieving that on any given game? Below is each players accuracy in a given game across all the simulations. You see that most games hover around the observed accuracy, but the variation in performances for a given game is clear to see.</p>
                        <ViolinDistribution
                            chartSettings={{ height: 300, marginLeft: 30, marginBottom: 60 }}
                            playerAccuracies={batchSimulations.playerAccuracies}
                            player1Accuracy={playerAttributes['player1']['regular']['make'] * 100}
                            player2Accuracy={playerAttributes['player2']['regular']['make'] * 100}
                        />
                    </div>

                    <BatchAccuracySummary
                        playerAttributes={playerAttributes}
                        batchSimulations={batchSimulations}
                    />

                    <div className="break-words mb-8">
                        <H2Heading>Game Lengths (shots)</H2Heading>
                        <Histogram
                            chartSettings={{ height: 240, marginTop: 0, marginBottom: 60, marginRight: 0, marginLeft: 45 }}
                            dataArray={batchSimulations.gameLengths}
                            filteredDataArray={batchSimulations.gameLengths}
                            xLabel="Game Length (total shots)"
                            medianValue={median(batchSimulations.gameLengths)}
                        />
                    </div>

                    <div>
                        <div className="mb-2">
                            <H2Heading><FireIcon className="h-6 w-6 text-badger-orange" />&nbsp;Top Streaks</H2Heading>
                            <p className="flex items-center justify-center text-sm sm:text-base text-center sm:text-left mt-2">You can see in how many matches a player managed to put together a long streak. A slight shift in accuracy allows a player to avoid 'low streak' games and hugely increases the chance of them getting at least a streak of 4 at some point in the game.</p>
                        </div>
                        <div className="flex flex-wrap items-center justify-center mb-4">
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 w-8 text-sm italic text-gray-500 text-center border-r">-</div>
                                <div className="flex justify-center items-center h-8 w-8 pr-2 italic border-r">P1</div>
                                <div className="flex justify-center items-center h-8 w-8 pr-2 text-xs text-gray-500 italic border-b border-r pb-2">%</div>
                                <div className="flex justify-center items-center h-8 w-8 pr-2 italic border-r pt-2">P2</div>
                                <div className="flex justify-center items-center h-8 w-8 pr-2 text-xs text-gray-500 italic border-r">%</div>
                            </div>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((shot, i) =>
                                <div
                                    className="flex flex-col text-center my-2"
                                    key={i}
                                >
                                    <div className="h-8 w-8 flex justify-center items-center text-sm italic text-gray-500">{i}</div>
                                    <div className="h-8 w-8 flex justify-center items-center text-sm">{batchSimulations.simulations.filter(simul => simul.player1TopStreak === i).length}</div>
                                    <div className="h-8 w-8 flex justify-center text-xs italic text-gray-500 items-center border-b pb-2">{displayPercentage(batchSimulations.simulations.filter(simul => simul.player1TopStreak === i).length / batchSimulations.simulations.length, false)}</div>
                                    <div className="h-8 w-8 flex justify-center items-center text-sm pt-2">{batchSimulations.simulations.filter(simul => simul.player2TopStreak === i).length}</div>
                                    <div className="h-8 w-8 flex justify-center text-xs italic text-gray-500 items-center">{displayPercentage(batchSimulations.simulations.filter(simul => simul.player2TopStreak === i).length / batchSimulations.simulations.length, false)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-8 text-center">
                        <H2Heading>Batch Simulation Results</H2Heading>
                        <p className="text-sm sm:text-base text-center sm:text-left mb-2">See a match summary of the first 50 simulated games.</p>
                        <BatchCarousel
                            slides={[...Array(batchSimulations.simulations.length).keys()].slice(0, 50)}
                            content={batchSimulations.simulations}
                        />
                    </div>
                </div>
            </Accordion>
        </PageBody>
    );
};
