// Loop through the fixtures and display them
// Each round is a div, each match is a p
export const FixturesDisplay = ({ fixtures }) => {
    return (
        <div className="flex flex-wrap mb-2">
            {fixtures.map((round, i) => (
                <div key={`round-${i}`} className="my-2 w-1/3 sm:w-1/5 text-center">
                    <p className="italic tracking-tight font-semibold">Round {i + 1}</p>
                    <div className="mt-1 text-sm sm:text-base">
                        {round.map((match, j) => (
                            <p key={`${i}-${j}`}>{match.player_1.player_id} vs. {match.player_2.player_id}</p>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};