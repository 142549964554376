import { displayPercentage } from "../../../../helpers";

const PlayerAttributesTable = ({ players }) => {
    return (
        <div className="text-sm sm:text-base text-center sm:text-left items-start">
            <div className="w-full rounded mb-4">
                <div className="text-center flex flex-row border-b">
                    <p className="w-1/3 border-r py-2">Players</p>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - id`} className="border-r border-t flex-1 flex items-center justify-center">{player.player_id}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row border-b">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2">Regular Play</p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Make Chance</p>
                    </div>
                    <div className="w-2/3 flex flex-row text-xs sm:text-base">
                        {players.map(player => (<div key={`${player.player_id} - regmake`} className="border-r flex-1 flex items-center justify-center">{displayPercentage(player['regular']['make'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2">Break</p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Make Chance</p>
                    </div>
                    <div className="w-2/3 flex flex-row text-xs sm:text-base">
                        {players.map(player => (<div key={`${player.player_id} - breakmake`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['make'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Split Benefit</p>
                    </div>
                    <div className="w-2/3 flex flex-row text-xs sm:text-base">
                        {players.map(player => (<div key={`${player.player_id} - breaksplit`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['splitBenefit'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row border-b">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Split Duration</p>
                    </div>
                    <div className="w-2/3 flex flex-row text-xs sm:text-base">
                        {players.map(player => (<div key={`${player.player_id} - breaksplitdur`} className="border-r flex-1 flex items-center justify-center">{player['break']['splitDuration']}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2">Foul</p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Regular Play</p>
                    </div>
                    <div className="w-2/3 flex flex-row text-xs sm:text-base">
                        {players.map(player => (<div key={`${player.player_id} - foulreg`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['regular']['foul'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Break</p>
                    </div>
                    <div className="w-2/3 flex flex-row text-xs sm:text-base">
                        {players.map(player => (<div key={`${player.player_id} - foulbreak`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['foul'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Pot Black</p>
                    </div>
                    <div className="w-2/3 flex flex-row text-xs sm:text-base">
                        {players.map(player => (<div key={`${player.player_id} - foulblack`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['foul']['makeBlack'])}</div>))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export { PlayerAttributesTable };