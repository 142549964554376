import "./App.css";
import { About } from "./views/About";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NoMatch } from "./views/errors/404.js";
import { TestCricketPlayers } from "./views/cricket/TestPlayers";
import { TestCricketProfile } from "./views/cricket/TestProfile";
import { HowToMaximiseBettingAccumulators } from "./views/betting/HowToMaximiseBettingAccumulators";
import { Home } from "./views/Home";
import { NavBar } from "./views/Utility";
import { DynamicD3 } from "./views/visualisation/DynamicD3";
import { Playground } from "./views/development/Playground";
import { HelmetProvider } from 'react-helmet-async';
import { BreakSimulation } from "./views/sportsModels/englishPool/BreakSimulation";
import { PoolSimulationIntroduction } from "./views/sportsModels/englishPool/PoolSimulationIntroduction";
import { AccuracySimulation } from "./views/sportsModels/englishPool/AccuracySimulation";
import ScrollToTop from "./ScrollToTop";
import { InterestingLines } from "./views/IntersectingLines";
import { PoolGlossary } from "./views/sportsModels/englishPool/PoolGlossary";
import { FoulSimulation } from "./views/sportsModels/englishPool/FoulSimulation";
import { PoolMatches } from "./views/pool/PoolMatches";
import { PoolPlayer } from "./views/pool/PoolPlayer";
import { PoolGroup } from "./views/pool/PoolGroup";
import { DesigningAnalyticsSchemas } from "./views/DesigningAnalyticsSchemas";
import { PoolPredictions } from "./views/pool/PoolPredictions";
import { BettingHome } from "./views/betting/BettingHome";
import { HowToFindValueBettingOnSports } from "./views/betting/HowToFindValueBettingOnSports";
import { UnderstandSportsBetting } from "./views/betting/UnderstandSportsBetting";
import { SportsModelsHome } from "./views/sportsModels/SportsModelsHome";
import { LeagueScheduleGenerator } from "./views/sportsModels/LeagueScheduleGenerator.js";
import { PlayerModel } from "./views/sportsModels/englishPool/PlayerModel";
import { BankrollManagementStrategies } from "./views/betting/BankrollManagementStrategies";
import { KellyCriterion } from "./views/betting/BankrollManagement/KellyCriterion";
import { SportsRankingMethods } from "./views/sportsModels/SportsRankingMethods";
import { PoolLeagueSimulation } from "./views/sportsModels/englishPool/LeagueSimulation";
import { MonteCarloSimulation } from "./views/sportsModels/MonteCarloSimulation";
import { GolfHome } from "./views/sportsModels/golf/GolfHome";
import { GolfRound } from "./views/sportsModels/golf/GolfRound";
import { CustomPoolLeagueSimulation } from "./views/sportsModels/englishPool/CustomLeagueSimulation";
// Create query client
const queryClient = new QueryClient();

function App() {
	return (
		<HelmetProvider>
			<BrowserRouter>
				<ScrollToTop>
					<QueryClientProvider client={queryClient}>
						<Routes>
							<Route path="/" element={<NavBar />}>
								<Route index element={<Home />} />
								<Route path="about" element={<About />} />

								<Route path="cricket" element={<TestCricketPlayers />} />
								<Route path="cricket/player/:playerName" element={<TestCricketProfile />} />
								<Route path="data-visualisation/dynamic-d3-components" element={<DynamicD3 />} />

								<Route path="betting" element={<BettingHome />} />
								<Route path="betting/understand-sports-betting" element={<UnderstandSportsBetting />} />
								<Route path="betting/how-to-find-value-betting-on-sports" element={<HowToFindValueBettingOnSports />} />
								<Route path="betting/how-to-maximise-sports-betting-accumulators" element={<HowToMaximiseBettingAccumulators />} />
								<Route path="betting/sports-betting-bankroll-management-strategies" element={<BankrollManagementStrategies />} />
								<Route path="betting/kelly-criterion-bankroll-management" element={<KellyCriterion />} />

								<Route path="sports-models" element={<SportsModelsHome />} />
								<Route path="sports-models/league-schedule-generator" element={<LeagueScheduleGenerator />} />
								<Route path="sports-models/sports-ranking-methods-colley-massey-elo" element={<SportsRankingMethods />} />
								<Route path="sports-models/monte-carlo-simulation-sports-prediction" element={<MonteCarloSimulation />} />

								{/* Pool Monte Carlo Simulation */}
								<Route path="sports-models/english-pool-monte-carlo-simulation" element={<PoolSimulationIntroduction />} />
								<Route path="sports-models/english-pool-monte-carlo-simulation/shot-accuracy" element={<AccuracySimulation />} />
								<Route path="sports-models/english-pool-monte-carlo-simulation/break-efficiency" element={<BreakSimulation />} />
								<Route path="sports-models/english-pool-monte-carlo-simulation/foul-impact" element={<FoulSimulation />} />
								<Route path="sports-models/english-pool-monte-carlo-simulation/player-model" element={<PlayerModel />} />
								<Route path="sports-models/english-pool-monte-carlo-simulation/league-predictions" element={<PoolLeagueSimulation />} />
								<Route path="sports-models/english-pool-monte-carlo-simulation/league-predictions/custom" element={<CustomPoolLeagueSimulation />} />
								<Route path="sports-models/english-pool-monte-carlo-simulation/glossary" element={<PoolGlossary />} />


								{/* Golf */}
								<Route path="sports-models/golf" element={<GolfHome />} />
								<Route path="sports-models/golf/round-prediction" element={<GolfRound />} />

								<Route path="playground" element={<Playground />} />
								<Route path="intersecting-lines" element={<InterestingLines />} />

								<Route path="pool/matches" element={<PoolMatches />} />
								<Route path="pool/player/:playerID" element={<PoolPlayer />} />
								<Route path="pool/group/:groupUrlSlug" element={<PoolGroup />} />
								<Route path="pool/predictions/:groupUrlSlug" element={<PoolPredictions />} />

								<Route path="analytics/designing-analytics-schemas" element={<DesigningAnalyticsSchemas />} />

								{/* Redirects */}
								<Route path="pool-monte-carlo-simulation" element={<Navigate to="/sports-models/english-pool-monte-carlo-simulation" />} />
								<Route path="pool-monte-carlo-simulation/shot-accuracy" element={<Navigate to="/sports-models/english-pool-monte-carlo-simulation/shot-accuracy" />} />
								<Route path="pool-monte-carlo-simulation/break-efficiency" element={<Navigate to="/sports-models/english-pool-monte-carlo-simulation/break-efficiency" />} />
								<Route path="pool-monte-carlo-simulation/foul-impact" element={<Navigate to="/sports-models/english-pool-monte-carlo-simulation/foul-impact" />} />
								<Route path="pool-monte-carlo-simulation/glossary" element={<Navigate to="/sports-models/english-pool-monte-carlo-simulation/glossary" />} />
								<Route path="concept/how-to-maximise-sports-betting-accumulators" element={<Navigate to="/betting/how-to-maximise-sports-betting-accumulators" />} />
								<Route path="visualisation/dynamic-d3-components" element={<Navigate to="/data-visualisation/dynamic-d3-components" />} />

								{/* Catch anything that is matched above */}
								<Route path="*" element={<NoMatch />} />
							</Route>
						</Routes>
					</QueryClientProvider>
				</ScrollToTop>
			</BrowserRouter>
		</HelmetProvider>
	);
};

export default App;
